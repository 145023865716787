<div class="modal-header">
  <h4 class="modal-title">Please enter full SSN</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<section>
  We could not locate you by the last 4 digits of your SSN. Please enter your full 9 digit SSN without dashes
  <div class="xppn_card">
    <!-- <div class="row rowspacing">
      <div class="col-10">
        <strong>Last Name:</strong> {{model.lastName}}
      </div>
    </div>
    <div class="row rowspacing">
      <div class="col-10">
        <strong>Date of Birth: </strong>
        {{model.dob}}
      </div>
    </div> -->
    <form class="form-inline" (ngSubmit)="submitSSN()">
      <div class="form-group row rowspacing">
        <div class="col-7">
          <!-- <input type="text" class="form-control" id="inputSSNFull" placeholder="Full SSN" (keyup)="onKey($event)"> -->
          <input type="password" class="form-control" #SSN id="SSN" [(ngModel)]="ssn" placeholder="#########" maxlength="9"  [ngModelOptions]="{standalone: true}">

          </div>
          <div class="col-4">
          <button type="submit" class="btn btn-primary mb-2  ppn_button--primary confirmation">Confirm identity</button>
        </div>
      </div>
    </form>
  </div>
</section>
</div>


