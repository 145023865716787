import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-error-radio-yes',
  templateUrl: './auth-error-radio-yes.component.html',
  styleUrls: ['./auth-error-radio-yes.component.css']
})
export class AuthErrorRadioYesComponent implements OnInit {
  @Input() res: any;
  @Input() modalBodyDescription : any;
  constructor(public activeModal: NgbActiveModal) {
  }
  ngOnInit() {
  
  }
}
