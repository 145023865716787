<div class="modal-header">
  <div class="strike"></div>
  <h4 class="modal-title"><span>WHY IS THIS INFORMATION REQUIRED?</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
   <span style="font-size:18px;">Close</span><span aria-hidden="true" style="font-size:25px;">&times;</span>
  </button>

</div>
<div class="modal-body">
<section>

  <div class="xppn_card">

  <p>Our commitment to our travel partners is to ensure that access to AmericanForcesTravel.com is strictly limited to only those who are verified as eligible.  The information requested allows us to verify through the Defense Enrollment Eligibility Reporting System (DEERS) that the user is eligible to use the services available on the site.</p>
  <p>Please be assured, we have limited our request to the absolute minimum information required to verify eligibility.  The verification process uses the latest in data encryption technology to ensure that your personal information is kept confidential throughout the process.  Once the process is complete, the information you have provided will be deleted.</p>
</div>
</section>
</div>
