<!-- <div class="modal-header">
    <div class="modal-title-oops">
        <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
          alt="Department of Defense logo">
       
      </div>
    <div class="strike" style="margin-top: 75px;"></div>
    
    <h4 class="modal-title" style="margin-top: 105px;">
        <span>Oops! Something went wrong</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span style="font-size:18px;">Close</span><span aria-hidden="true" style="font-size:25px;">&times;</span>
    </button>
</div> -->
<div data-backdrop="true">
    <header class="p_header-opps">
      <div class="modal-header">
        <!-- <div class="strike"></div> -->
        <div class="modal-title-oops">
          <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
            alt="Department of Defense logo">
          <span class="modal-header-span">OOPS! SOMETHING WENT WRONG</span>
        </div>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span style="font-size:18px;">Close</span>
          <span aria-hidden="true" style="font-size:25px;">&times;</span>
        </button>
      </div>
    </header>
<div class="modal-body">
    <section>
        <div class="ppn_card" style="margin-top: -20px;">
                <h5>Dear Customer</h5>
                <div style="text-align: left;">
                    <span  class="modal-title-oops"><strong>Our records show </strong></span>
                    <p style="color:red; display:inline-block; font-weight: bold;"> {{res.description}}</p>
                </div>
            <!-- <h3 class="ppn_heading ppn_heading--large">All Customers</h3> -->
            <p>Please review the information you have provided.
            </p>
            <ul>
                <li>
                    <strong>Social Security Number </strong> (last 4 or full if necessary)</li>
                <li>
                    <strong> Date of birth</strong> (Month/Day/Year)</li>
                <li>
                    <strong>Last name</strong>, which must match your official DEERS record. If you have a suffix in your last name, include this when logging in, using the letter “i” for roman numerals and do not include punctuation.</li>
                    <li style="list-style-type:none;">Examples:</li>
                    <li style="list-style-type:none;">Smith Jr</li>
                    <li style="list-style-type:none;">Smith III</li>
            </ul>
            <p>Access to AFT is restricted to eligible patrons only. Click on ‘Who we serve?’ to check eligibility categories and resource links.
            </p>
            <a class="ppn_type--link" (click)="toggle()">
                <i *ngIf="expandWhoWeServe" class="fa fa-chevron-circle-right large-fontsize"></i>
                <i *ngIf="!expandWhoWeServe" class="fa fa-chevron-circle-down large-fontsize"></i>
                Who we serve?</a><br>
            <a class="ppn_type--link" (click)="toggleInformationCorrect()">
                        <i *ngIf="expandInformationCorrect" class="fa fa-chevron-circle-right large-fontsize"></i>
                        <i *ngIf="!expandInformationCorrect" class="fa fa-chevron-circle-down large-fontsize"></i>
                Information correct, but still can't login?</a>
        </div>
    </section>
    <section *ngIf="expandWhoWeServe" class="ppn_section ppn_section--withShadow">
        <div class="ppn_card">
            <h3 class="ppn_heading ppn_heading--large">Who we serve</h3>
                <p>American Forces Travel<sup>SM</sup> is committed to providing high-quality and best value travel services to patrons affiliated with the Department
                  of Defense as a way to thank them for their service and dedication to our country.
                </p>
                <p>
                  <strong>Authorized Patrons Today: </strong>
                </p>
                <ul class="point_height">
                    <li>All current active duty military (Army, Marine Corps, Navy, Air Force, and Coast Guard)</li>
                    <li>All members of the Reserve components and National Guard</li>
                    <li>Coast Guard Auxiliary Active Duty</li>
                    <li>All current active duty and retired National Oceanic and Atmospheric Administration (NOAA)</li>
                    <li>All current active duty and retired US Public Health Service (USPHS)</li>
                    <li>All retired military, including those in the Reserves and National Guard who are retirement eligible</li>
                    <li>All Medal of Honor recipients</li>
                    <li><p class="PDF-link" style="margin-bottom: 0;">All veterans with service-connected disabilities, all veterans who are Purple Heart recipients, all veterans who are former prisoners of war, all individuals approved and designated as the primary family caregivers of eligible veterans under the Department of Veterans Affairs Program of Comprehensive Assistance for Family Caregivers.</p>
                    </li>
                    <li>Eligible family members who are officially sponsored (ID card holder) by authorized patrons in the above categories</li>
                    <li>All Department of Defense and Coast Guard civilians, including appropriated funds employees and non-appropriated funds employees
                    </li>
                    <li>Full-time, paid employees of the American Red Cross and United Service Organizations (USO) hired in the United States and serving at U.S. DoD installations overseas</li>
                    <li>All Veterans of the United States Armed Forces with a discharge status of honorable or general under honorable conditions.</li>
                </ul>
                
                <!-- <p class="noteSection">Note: The online eligibility verification process for stateside civilians is under development. In the meantime, we invite civilians to contact their MWR ticket and travel office. As eligible patrons, agents will be able to assist you with your travel needs allowing you to take advantage of the AFT deals as well.</p> -->
                <hr class="ppn_hr">
                <p class="noteSection" style="margin-bottom: 0;">Please read the above message before trying again. If you are still unsuccessful with authentication, but fall within the eligibility stipulations above, please contact us via private message on our Facebook page at: </p> <a class="facebook-link noteSection" target="_blank" style="text-decoration: underline;" href="https://www.facebook.com/AFTMWR/">www.facebook.com/AFTMWR.</a>
            <!-- <h5 class="ppn_heading ppn_heading--medium ppn_align--center">Please read the above message before trying again.</h5> -->
        </div>
    </section>
    <section *ngIf="expandInformationCorrect" class="ppn_section ppn_section--withShadow">
            <div class="ppn_card">
                <h3 class="ppn_heading ppn_heading--large">Information correct, but still can't login?</h3>
                    <p>If you are eligible and have entered your data correctly and still cannot access AFT please follow the instructions below:
                    </p>
                    <p>
                      <strong>Active Duty, Retiree, Reservist, National Guard, Civilian, Disabled Veteran</strong>
                    </p>
                    <ul style="list-style-type:none;">
                        <li>1) Call DMDC 1-800-727-3677</li>
                        <li>a.Ask to conﬁrm your: SSN, all characters of your last name, date of birth</li>
                        <li>b.Ask to conﬁrm that your details have the MWR ﬂag</li>
                        <li>2) If the information you provide to DMDC matches what is on ﬁle, and you are still unable to authenticate, go to the American Forces Travel Facebook page: facebook.com/AFTMWR and send a private message for assistance.</li>
                    </ul>
                    <p>
                          <strong>All other Veterans</strong>
                          </p>
                          <ul style="list-style-type:none;">
                              <li>1) Are you an honorably discharged vet? If yes, proceed to question #2.</li>
                              <li>2) Have you been successfully authenticated through Vet Verify [Received an email conﬁrming your approval] and/or successfully created an online account at one of the exchanges: [Shopmyexchange.com, NEX.com, MCX.com, CGX.com]? If so, please go to American Forces Travel Facebook page, facebook.com/AFTMWR for assistance. If you have not received an approval email from VetVerify or have not been able to successfully create an online account at one of the exchanges in the past, please go to Vetverify.org and go through the authentication process at the site.</li>
                          </ul>
            </div>
        </section>
</div>
