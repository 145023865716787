<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
</ngx-spinner>
<h1 class="ppn_heading ppn_align--left">Verify Your Eligibility</h1>
<p *ngIf="!submitted" class="ppn_type--small ppn_align--left text-left ppn_type--link">
    <a (click)="openEligible()">Learn who is eligible</a>
</p>
<form name="form" (ngSubmit)="f.form.valid && onYesSubmit()" #f="ngForm" class="ppn_form_section" novalidate
    autocomplete="nope">
    <!-- <div *ngIf="!submitted"> -->
    <div>
        <div class="ppn_fieldset">
            <label>Are you a member of the
                <b>Coast Guard
                    <br /> Auxiliary?</b>
            </label>
            <div class="desktop_radiobutton">
                <div class="form-check form-check-inline custom-control custom-radio custom-control-inline">
                    <input (change)="handleChange($event)" type="radio" id="defaultInline1" value="false"
                        name="inlineDefaultRadiosExample">
                    <label class="form-check-label custom-control-label" for="defaultInline1"
                        style="margin-top: -23px;margin-left: 24px;">No</label>
                </div>
                <div class="form-check form-check-inline custom-control custom-radio custom-control-inline"
                    style="margin-left: -30px;">
                    <input (change)="handleChange($event)" type="radio" id="defaultInline2" value="true"
                        name="inlineDefaultRadiosExample" checked>
                    <label class="form-check-label custom-control-label" for="defaultInline2"
                        style="margin-top: -23px;margin-left: 24px;">Yes</label>
                </div>
            </div>
        </div>

        <fieldset class="ppn_fieldset">
            <label>Emp ID (Applicable to US Coast Guard only)</label>

            <input type="text" [(ngModel)]="empNumbers" #empId="ngModel" class="form-control ppn_input ppn_input--user"
                [ngClass]="{'is-invalid':f.submitted && empId.invalid}" name="empId" autocomplete="off" maxlength="10"
                required />
            <div *ngIf="f.submitted && empId.invalid" class="invalid-feedback">
                <div *ngIf="empId.errors">Employee Id is required</div>
            </div>
        </fieldset>


        <fieldset class="ppn_fieldset">
            <label>Last Name</label>
            <input type="text" class="form-control ppn_input ppn_input--user" [(ngModel)]="lastNames"
                #lastName="ngModel" [ngClass]="{'is-invalid':f.submitted && lastName.invalid}" name="lastName"  (keydown.Tab)="toUpperSurname($event)"
                autocomplete="off" required maxlength="40" pattern=".*[^\s].*" />
            <div *ngIf="f.submitted && lastName.invalid" class="invalid-feedback">
                <div *ngIf="lastName.errors">Last Name is required</div>
            </div>
        </fieldset>

        <label class="remember">REMEMBER ME</label>
        <fieldset class="ppn_fieldset ppn_fieldset--checkbox">
            <input type="checkbox" name="rememberme" value="false" [(ngModel)]="rememberMe">
            <label>Keep me logged in on this computer.
                <a class="detailLink" style="text-decoration: underline;
                color: #00468c;" (click)="openDetails()">Details</a>
            </label>
        </fieldset>
        <fieldset class="ppn_fieldset">
            <button type="submit" [ngClass]="{'xisnotok': f.invalid }"
                class="ppn_button ppn_button--primary ppn_button--large">Submit</button>
        </fieldset>
        <p class="ppn_type--small ppn_align--center ppn_heading--caps">
            <img src="./assets/icons/lock.svg" alt="">&nbsp;&nbsp;Secure Authentication
        </p>
    </div>
</form>