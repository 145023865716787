import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-system-error',
  templateUrl: './system-error.component.html',
  styleUrls: ['./system-error.component.css']
})
export class SystemErrorComponent implements OnInit {

  @Input() res:any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    //console.log('>>>>',this.res);
  }

}
