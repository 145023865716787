import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-error-popup',
  templateUrl: './auth-error-popup.component.html',
  styleUrls: ['./auth-error-popup.component.css']
})
export class AuthErrorPopupComponent implements OnInit {
  @Input() res: any;
  // @Input() description: any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
