<div class="modal-header">
  <div class="strike"></div>
  <h4 class="modal-title">
    <span>WHO WE SERVE</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span style="font-size:18px;">Close</span>
    <span aria-hidden="true" style="font-size:25px;">&times;</span>
  </button>

</div>
<div class="modal-body">
  <section>
    <div class="xppn_card lefteligible">
      <p>American Forces Travel<sup>SM</sup> is committed to providing high-quality and best value travel services to patrons affiliated with the Department
        of Defense as a way to thank them for their service and dedication to our country.
      </p>
      <p>
        <strong>Authorized Patrons Today: </strong>
      </p>
      <ul class="point_height">
        <li>All current active duty military (Army, Marine Corps, Navy, Air Force, and Coast Guard)</li>
        <li>All members of the Reserve components and National Guard</li>
        <li>Coast Guard Auxiliary Active Duty</li>
        <li>All current active duty and retired National Oceanic and Atmospheric Administration (NOAA)</li>
        <li>All current active duty and retired US Public Health Service (USPHS)</li>
        <li>All retired military, including those in the Reserves and National Guard who are retirement eligible</li>
        <li>All Medal of Honor recipients</li>
        <li><p class="PDF-link" style="margin-bottom: 0;">All veterans with service-connected disabilities, all veterans who are Purple Heart recipients, all veterans who are former prisoners of war, all individuals approved and designated as the primary family caregivers of eligible veterans under the Department of Veterans Affairs Program of Comprehensive Assistance for Family Caregivers.</p>
        </li>
        <li>Eligible family members who are officially sponsored (ID card holder) by authorized patrons in the above categories</li>
        <li>All Department of Defense and Coast Guard civilians, including appropriated funds employees and non-appropriated funds employees
        </li>
        <li>Full-time, paid employees of the American Red Cross and United Service Organizations (USO) hired in the United States and serving at U.S. DoD installations overseas</li>
        <li>All Veterans of the United States Armed Forces with a discharge status of honorable or general under honorable conditions.</li>
      </ul>
     
      <!-- <p class="noteSection">Note: The online eligibility verification process for stateside civilians is under development. In the meantime, we invite civilians to contact their MWR ticket and travel office. As eligible patrons, agents will be able to assist you with your travel needs allowing you to take advantage of the AFT deals as well.</p> -->
    </div>
  </section>
</div>
