import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-full-ssn',
  templateUrl: './auth-full-ssn.component.html',
  styleUrls: ['./auth-full-ssn.component.css']
})
export class AuthFullSSNComponent implements OnInit {

  @Input() dob: any;
  @Input() name: any;
  @Input() rememberMe: any;
  @Input() model: any;
  ssn: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  submitSSN() {
    this.model.ssn = this.ssn.replace(/-/g, '');
    this.activeModal.close(this.model);
  }

  // ssnInput(item) {
  //   if (item.length === 3) {this.ssn = item + '-'}
  //   if (item.length === 6) {this.ssn = item + '-'}

  // }
}
