<!--The content below is only a placeholder and can be replaced.-->
<div class="ppn_styles">
    <div>
        <header class="p_header">
            <section class="ppn_header__container">
                <img class="header_logo" src="./assets/images/logo.png" alt="americanforcestravel" />
            </section>
        </header>
        <div>
            <section class="ppn_section">
                <div class="displayvisibility">
                    <div style="margin-right:2%;">
                        <img class="whenregular dodlogo" src="./assets/images/logo-dod-small.png"
                            alt="Department of Defense logo">
                        <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
                            alt="Department of Defense logo">
                    </div>
                    <div>
                        <div class="p_auth">Authorization Required</div>
                        <div class="p_access">Access to
                            <a href="https://www.americanforcestravel.com/" target="_blank"
                                style="color:#2292E9">AmericanForcesTravel.com</a>
                                discounts are strictly limited to those who are verified as MWR eligible.
                        </div>
                    </div>
                </div>
            </section>

            <section class="ppn_notification_desktop">
                <img class="whenregular dodlogo" src="./assets/images/flag.png" alt="flag">
                <span style="margin-left:12px;">Eligibility is being verified through DEERS per
                    <a href="https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/101510p.pdf?ver=2019-04-08-125319-650"
                        target="_blank" class="link">DODI 1015.10
                    </a> and
                    <a href="https://media.defense.gov/2023/Sep/29/2003311039/-1/-1/0/CI_1710_13E.PDF" target="_blank"
                        class="link">COMDTINST 1710.13E</a>
                </span>

            </section>

            <section class="ppn_notification_mobile">
                <img src="./assets/images/flag.png" alt="flag">
                <div  class="eligibility_heading"><span style="text-align:justify;">Eligibility is being verified through DEERS per
                        <a href="https://www.esd.whs.mil/Portals/54/Documents/DD/issuances/dodi/101510p.pdf?ver=2019-04-08-125319-650"
                            target="_blank" class="link">DODI 1015.10</a> and
                        <a href="https://media.defense.gov/2023/Sep/29/2003311039/-1/-1/0/CI_1710_13E.PDF"
                            target="_blank" class="link">COMDTINST 1710.13E</a></span>
                </div>
                <div style="margin-left: 70px;padding-top: 10px;" class="link">
                    <a href="#privacy" style="color: #00468C;">Learn about data privacy</a>
                </div>

            </section>

            <section class="ppn_section ppn_section--withSplit ppn_section--withShadow">
                <div class="ppn_card ppn_card--left">
                    <router-outlet></router-outlet>
                </div>

                <div id="privacy" class="ppn_card ppn_card--right ppn_card--withBackgroundImage">
                    <h2 class="privacy_heading">Data privacy
                        <img src="./assets/images/lock.png" alt="lock">
                    </h2>
                    <p class="privacy_text ppn_type--small">
                        Please be assured, we have limited our request to the absolute minimum information required to
                        verify eligibility. The verification
                        process uses the latest in data encryption technology to ensure that your personal information
                        is
                        kept confidential throughout the process. Once the process is complete, the information you have
                        provided
                        will be deleted.
                    </p>
                    <a class="ppn_type--small" (click)="openPrivacy()"
                        style="text-decoration: underline;cursor: pointer;">Privacy Act Statement</a>
                    <ul>
                        <li>
                            <img class="whenregular dodlogo" src="./assets/images/Group 9.png">
                        </li>
                        <li>
                            <img class="whenregular dodlogo" src="./assets/images/Group 10.png">
                        </li>
                        <li>
                            <img class="whenregular dodlogo" src="./assets/images/Group 8.png">
                        </li>
                        <li>
                            <img class="whenregular dodlogo forcelogo" src="./assets/images/mwr-logo.png">
                        </li>
                        <li>
                            <img class="whenregular dodlogo" src="./assets/images/Group 7.png">
                        </li>
                    </ul>
                </div>
            </section>
            <p class="p_footer">
                The appearance of external hyperlinks does not constitute endorsement by the United States Department of
                Defense of the linked
                web sites, or the information, products or services contained therein. For other than authorized
                activities
                such as military exchanges and Morale, Welfare and Recreation (MWR) sites, the United States Department
                of
                Defense do not exercise any editorial control over the information you may find at these locations. All
                provided
                links are consistent with the stated purpose of this web site.
            </p>
        </div>

    </div>
</div>
