<header class="p_header-opps">
  <div class="modal-header">
    <!-- <div class="strike"></div> -->
    <div class="modal-title-oops">
      <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
        alt="Department of Defense logo">
      <span class="modal-header-span">OOPS! SOMETHING WENT WRONG</span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span style="font-size:18px;">Close</span>
      <span aria-hidden="true" style="font-size:25px;">&times;</span>
    </button>
  </div>
</header>
<div class="modal-body">
<section>
  <!-- <h5>Dear Customer</h5> -->
  <!-- <div style="text-align: left;">
      <span  class="modal-title-oops"><strong>Our records show </strong></span>
      <p style="color:red; display:inline-block; font-weight: bold;"> {{res.description}}</p>
  </div> -->
  <div class="xppn_card">
      <div class="text-align" [innerHtml]="modalBodyDescription"></div>
    </div>
  </section>
</div>
