import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthErrorRadioYesComponent } from '../auth-error-radio-yes/auth-error-radio-yes.component';
import { PageValues } from '../../common/pageValue';
import { CoreService } from '../../common/core.service';
import { environment } from 'src/environments/environment';
import { AuthEligibleComponent } from '../auth-eligible/auth-eligible.component';
import { DetailsComponent } from '../details/details.component';
import { SystemErrorComponent } from '../system-error/system-error.component';
import { timeout } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-radio-yes',
  templateUrl: './radio-yes.component.html',
  styleUrls: ['./radio-yes.component.css']
})
export class RadioYesComponent {
  title = 'AmericanForcesTravel';
  referrer = '';
  rememberMe : any;
  model: any = { rememberMe: '0', dob: '', lastName: '', ssn: '', bFullSSN: false };
  submitted = false;
  isCoastGuard = 'CoastGuard';
  modelyes: any = { "rememberMe": '0', "emp-number": '' };
  empNumbers: any;
  lastNames: any;
  googleAnalytics: any;
  callbackuri : any;
  cgaUrl;
  searchparam : any;
  post_response : any;
  modalRef : any;
  constructor(private modalService: NgbModal, private http: HttpClient, private routing: ActivatedRoute,
    private route: Router, private coreService: CoreService,private SpinnerService: NgxSpinnerService) {
    this.cgaUrl = environment.CGA;
    this.routing.queryParams
      .subscribe(params => {
        this.callbackuri = params['callback_uri'];
      });
  }
  toUpperSurname(event:any) {
    this.lastNames = this.lastNames.toUpperCase();
  }
  /**
   * Handle changes according to radio button click
   * @param es 
   */
  handleChange(es) {
      this.isCoastGuard = 'Others';
      if (this.searchparam.callback_uri == "undefined" || this.searchparam.callback_uri == undefined) {
        this.route.navigate(["Others"]);
      } else {
        this.route.navigate(["Others"], { queryParams: { callback_uri: decodeURIComponent(this.searchparam.callback_uri) } });
      }
  }
  ngOnInit() {
    this.searchparam = this.searchToObject();
    this.referrer = this.searchparam['callback_uri'];

  }
  /**
   * Getting callback uri
   */
  searchToObject() {
    const pairs = window.location.search.substring(1).split('&'),
    obj: any= {};
    let pair;
    for (const i in pairs) {
      if (pairs[i] === '') { continue; }
      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = (pair[1]);
    }
    return obj;
  }

  /**
   * Open popup for why this information required 
   */
  openEligible() {
    const modalRef = this.modalService.open(AuthEligibleComponent, { size: 'lg' });
  }
  /**
   * Open detail popup
   */
  openDetails() {
    const modalRef = this.modalService.open(DetailsComponent, { size: 'lg' });
  }
  /**
   * Open radio yes popup for status code[900,908]
   * @param resp 
   */

  /**
   * Open popup for any other errors
   * @param resp
   */

  /**
   * Submit yes option details
   */

  onYesSubmit() {
    this.modelyes["rememberMe"] = (this.rememberMe === true) ? '1' : '0';
    this.modelyes["emp-number"] = this.empNumbers;
    this.modelyes["lastName"] = this.lastNames;
    this.submitted = true;
    this.SpinnerService.show();
    console.log(this.modelyes);
    const request = this.http.post(this.cgaUrl, this.modelyes, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(timeout(5000));
    request.subscribe(data => {
      var refer = this.referrer;
      if(refer=="undefined"){
        refer = '';
      }
      const callback = refer ? `&callback_uri=${refer}` : '';
      this.post_response = data;
      // console.log(this.post_response);
      this.SpinnerService.hide();
      if (this.post_response.status === '100') {
        this.submitted = true;
        this.googleAnalytics = { "Action": "Success", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        const uriencoded =
          `https://www.americanforcestravel.com/wl/sso/?remember_me=${ this.post_response.rememberMe}&token=${this.post_response.token}${callback}`;
        window.location.href = uriencoded;
      } else if (this.post_response.token === '900') {
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        this.modalRef = this.modalService.open(AuthErrorRadioYesComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
        this.modalRef.componentInstance.modalBodyDescription = PageValues.INVALID_EMP_NUMBER;
        this.submitted = false;
      } else if (this.post_response.token === '908') {
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        this.modalRef = this.modalService.open(AuthErrorRadioYesComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
        this.modalRef.componentInstance.modalBodyDescription = PageValues.LAST_NAME_DOES_NOT_MATCH;
        this.submitted = false;
      }
      // else if (this.post_response.status === '903' || this.post_response.status === '904' || this.post_response.status === '905' || this.post_response.status === '906' || this.post_response.status === '907' || this.post_response.status === '908' || this.post_response.status === 'E1905' || this.post_response.status === 'E1906') {
      //   this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.remember_me, "Token": this.post_response.token }
      //   this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
      //   this.modalRef.componentInstance.res = this.post_response;
      //   this.submitted = false;
      // } 
      else {
        this.googleAnalytics = { "Action": "Error", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      this.coreService.getGoogleAnalytics(this.googleAnalytics);
    }, error => {
      var refer = this.referrer;
      if(refer=="undefined"){
        refer = '';
      }
      const callback = refer ? `&callback_uri=${refer}` : '';
      this.SpinnerService.hide();
      // console.log(error.error);
      if (error.error.status === '903' || error.error.status === '904' || error.error.status === '905' || error.error.status === '906' || error.error.status === '907' || error.error.status === 'E1905' || error.error.status === 'E1906') {
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = error.error;
        this.submitted = false;
      }
      else{
        this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
        this.submitted = false;
        
      }
    });
  }
}
