<div class="ppn_styles" ng-app="pageLoadApp" ng-controller="pageLoadAppCtrl">
  <div data-ng-init="onloadFun()"></div>

  <header class="ppn_header ppn_header--small">

      <section class="ppn_header__container">

          <h1 class="ppn_heading ppn_heading--medium ppn_align--center">Authentication is being checked on a US Government (USG) Information System (IS)</h1>
      </section>
  </header>

  <section class="ppn_section">

      <div class="ppn_branding">

          <a href="index.html" class="ppn_logo"><img src="images/logo-dod-small.png" alt="Department of Defence logo"><img src="images/logo-aft-small.png" alt="American Forces Travel logo"></a>
      </div>
  </section>

  <section class="ppn_section ppn_section--withSplit ppn_section--withShadow">

      <div class="ppn_card ppn_card--left">

          <h1 class="ppn_heading ppn_heading--xlarge ppn_heading--caps ppn_align--center">Authentication</h1>

          <form class="ppn_form_section">

              <p class="ppn_type--small ppn_align--center"><a href="" class="ppn_type--link">Why is this information required?</a></p>
              
              <div class="ppn_fieldset-group">

                  <fieldset class="ppn_fieldset">
                      <label>Last 4 Digits of SSN</label>
                      <input type="number" name="name" value="" class="ppn_input ppn_input--card" placeholder="">
                  </fieldset>

                  <fieldset class="ppn_fieldset">
                      <label>Date of Birth (dd/mm/yyyy)</label>
                      <input type="text" name="name" value="" class="ppn_input ppn_input--birthday" placeholder="">
                  </fieldset>
              </div>

              <fieldset class="ppn_fieldset">
                  <label>Last Name</label>
                  <input type="text" name="name" value="" class="ppn_input ppn_input--user" placeholder="">
              </fieldset>

              <fieldset class="ppn_fieldset ppn_fieldset--checkbox">
                  <label><input type="checkbox" name="name" value=""> Remember Me</label>
              </fieldset>

              <fieldset class="ppn_fieldset">
                  <button type="submit" class="ppn_button ppn_button--primary ppn_button--large">Check My Eligibility</button>
              </fieldset>

              <p class="ppn_type--small ppn_align--center ppn_heading--caps">
                  <img src="icons/lock.svg" alt="">&nbsp;&nbsp;Secure Authentication
              </p>  

               <div class="ppn_type--small ppn_align--center">
                  <a href="" class="ppn_type--link">Privacy Act Statement</a>
              </div>

              <div class="ppn_loading ppn_align--center">

                  <small class="ppn_type--small">Please wait...</small>

                  <div class="ppn_loading__animation">

                      <div class="sk-fading-circle">
                          <div class="sk-circle1 sk-circle"></div>
                          <div class="sk-circle2 sk-circle"></div>
                          <div class="sk-circle3 sk-circle"></div>
                          <div class="sk-circle4 sk-circle"></div>
                          <div class="sk-circle5 sk-circle"></div>
                          <div class="sk-circle6 sk-circle"></div>
                          <div class="sk-circle7 sk-circle"></div>
                          <div class="sk-circle8 sk-circle"></div>
                          <div class="sk-circle9 sk-circle"></div>
                          <div class="sk-circle10 sk-circle"></div>
                          <div class="sk-circle11 sk-circle"></div>
                          <div class="sk-circle12 sk-circle"></div>
                      </div>
                  </div>
              </div>
          </form>
      </div>

      <div class="ppn_card ppn_card--right ppn_card--withBackgroundImage">
          
          <h2 class="ppn_heading ppn_heading--large ppn_heading--caps">Why Authenticate</h2>

          <ul class="ppn_list ppn_list--checked">
              <li>
                  <p>Exclusive program for only Active Military, Retired Military and Eligible Military Family Members</p>
              </li>
              <li>
                  <p>Save up to 60% off Hotels</p>
              </li>
              <li>
                  <p>One stop shop for all your travel needs</p>
              </li>
          </ul>
      </div>
  </section>

  <div class="ppn_modal ppn_modal--isOpen">

      <div class="ppn_modal__content">
          
          <section class="ppn_section ppn_section--small ppn_section--withShadow">

              <div class="ppn_card">

                  <i>
                      <svg width="59px" height="59px" viewBox="0 0 59 59" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                          <defs></defs>
                          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <circle fill="#E1F1FF" fill-rule="nonzero" cx="29.5" cy="29.5" r="29.5"></circle>
                              <circle fill="#BAD8F2" fill-rule="nonzero" cx="29.5" cy="29.5" r="23.5"></circle>
                              <polygon fill="#0F2E4A" fill-rule="nonzero" points="19.2734375 33.0610352 7.17724609 25.4423828 48.6191406 4.45898438 41.6689453 44.347168 27.8981934 37.2822266 20.6904297 46.8554688 20.6904297 38.3828125 39.3105469 16.4057617"></polygon>
                          </g>
                      </svg>
                  </i>

                  <h1 class="ppn_heading ppn_heading--large ppn_align--center">You Are Now Leaving...</h1>

                  <hr class="ppn_hr">

                  <p class="ppn_heading--medium ppn_align--center">You are now transitioning to an Official Department of Defense site.</p>
              </div>
          </section>
      </div>
  </div>
</div>
