<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
  </ngx-spinner>
<h1 class="ppn_heading ppn_align--left">Verify Your Eligibility</h1>
<p *ngIf="!submitted" class="ppn_type--small ppn_align--left text-left ppn_type--link">
    <a (click)="openEligible()">Learn who is eligible</a>
</p>
<form name="form" (ngSubmit)="f.form.valid && onSubmit(fullssn)" #f="ngForm" class="ppn_form_section" novalidate autocomplete="nope">
    <!-- <div *ngIf="!submitted"> -->
        <div>
        <div class="ppn_fieldset">
            <label>Are you a member of the
                <b>Coast Guard
                    <br/> Auxiliary?</b>
            </label>
            <div class="desktop_radiobutton">
                <div class="form-check form-check-inline custom-control custom-radio custom-control-inline">
                    <input (change)="handleChange($event)" type="radio" id="defaultInline1" value="false" name="inlineDefaultRadiosExample"
                        checked>
                    <label class="form-check-label custom-control-label" for="defaultInline1" style="margin-top: -23px;margin-left: 24px;">No</label>
                </div>
                <div class="form-check form-check-inline custom-control custom-radio custom-control-inline" style="margin-left: -30px;">
                    <input (change)="handleChange($event)" type="radio" id="defaultInline2" value="true" name="inlineDefaultRadiosExample">
                    <label class="form-check-label custom-control-label" for="defaultInline2" style="margin-top: -23px;margin-left: 24px;">Yes</label>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="fullssn" class="redbox redcontent border border-danger">
                <strong>We could not locate you by the last 4 digits of your SSN. Please enter your full 9 digit SSN without dashes</strong>
            </div>
            <div class="ppn_fieldset-group">
                <fieldset *ngIf="!fullssn" class="ppn_fieldset ssn_field">
                    <label>Last 4 Digits of SSN</label>
                    <button type="button" class="btn showhide" (click)="toggleSsnVisibility()" style="background: #f2f3f4;">
                        <i *ngIf="ssnvisibility" class="fa fa-eye"></i>
                        <i *ngIf="!ssnvisibility" class="fa fa-eye-slash"></i>
                    </button>
                    <input [type]="ssnfieldtype" class="form-control ppn_input ppn_input--card " [(ngModel)]="model.ssn" #ssn="ngModel" pattern="[0-9]{4}"
                        [ngClass]="{'is-invalid':f.submitted && ssn.invalid}" name="ssn" required autocomplete="new-password" />
                    <div class="invalid-feedback">
                        <div>Last 4 digits of SSN is required</div>
                    </div>
                </fieldset>
                <fieldset *ngIf="fullssn == true" class="ppn_fieldset ssn_field">
                    <label>Last 9 Digits of SSN</label>
                    <button type="button" class="btn showhide" (click)="toggleSsnVisibility()" style="background: #f2f3f4;">
                        <i *ngIf="ssnvisibility" class="fa fa-eye"></i>
                        <i *ngIf="!ssnvisibility" class="fa fa-eye-slash"></i>
                    </button>
                    <input [type]="ssnfieldtype" class="form-control ppn_input ppn_input--card " [(ngModel)]="model.ssn" #ssn="ngModel" pattern="[0-9]{9}"
                        [ngClass]="{'is-invalid':f.submitted && ssn.invalid}" name="ssn" required autocomplete="new-password"/>
                    <div class="invalid-feedback">
                        <div>Full SSN is required</div>
                    </div>
                </fieldset>
                <fieldset class="ppn_fieldset dob_field">
                    <label>Date of Birth</label>
                    <div class="form-group btn-group dob_group" role="group">
                        <select class="form-control form-select" [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" id="month" [(ngModel)]="mm" name="month"
                            #month="ngModel" required style="height: 50px;">
                            <option value="" disabled selected>mm</option>
                            <option *ngFor="let month of monthList()">{{month}}</option>
                        </select>
                        <select class="form-control form-select" [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" id="day" [(ngModel)]="dd" name="day"
                            #day="ngModel" required style="height: 50px;">
                            <option value="" disabled selected>dd</option>
                            <option *ngFor="let day of days()">{{day}}</option>
                        </select>
                        <select class="form-control form-select" [ngClass]="{'is-invalid':f.submitted && invalidDob(mm,dd,yy)}" id="year" [(ngModel)]="yy" name="year"
                            #year="ngModel" (change)="changeYear($any($event.target).value)" required style="height: 50px;">
                            <option value="" disabled selected>yyyy</option>
                            <option *ngFor="let year of years()">{{year}}</option>
                        </select>
                    </div>
                    <div *ngIf="invalidDate" class="invalid-feedback" style="display:block;margin-top:-4%;">Date of birth is required</div>
                </fieldset>
            </div>
            <fieldset class="ppn_fieldset" style="margin-top:-18px;">
                <label>Last Name</label>
                <input type="text" class="form-control ppn_input ppn_input--user" [(ngModel)]="model.lastName" #lastName="ngModel"
                    [ngClass]="{'is-invalid':f.submitted && lastName.invalid}" name="lastName" autocomplete="off" maxlength="35" required
                />
                <div class="invalid-feedback">
                    <div>Last name is required</div>
                </div>
            </fieldset>
        </div>
        <label class="remember">REMEMBER ME</label>
        <fieldset class="ppn_fieldset ppn_fieldset--checkbox">
            <input type="checkbox" name="rememberme" value="false" [(ngModel)]="rememberMe">
            <label>Keep me logged in on this computer.
                <a class="detailLink" style="text-decoration: underline;
                color: #00468c;" (click)="openDetails()">Details</a>
            </label>
        </fieldset>
        <fieldset class="ppn_fieldset">
            <button type="submit" [ngClass]="{'xisnotok': f.invalid }" class="ppn_button ppn_button--primary ppn_button--large" ng-click="postdata()">Submit</button>
        </fieldset>
        <p class="ppn_type--small ppn_align--center ppn_heading--caps">
            <img src="./assets/icons/lock.svg" alt="">&nbsp;&nbsp;Secure Authentication
        </p>
    </div>
</form>