import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthPrivacyComponent } from './auth-privacy/auth-privacy.component';
import { AuthWhyComponent } from './auth-why/auth-why.component';
import { AuthLeavingComponent } from './auth-leaving/auth-leaving.component';
import { AuthErrorComponent } from './auth-error/auth-error.component';
import { AuthFullSSNComponent } from './auth-full-ssn/auth-full-ssn.component';
import { AuthErrorPopupComponent } from './auth-error-popup/auth-error-popup.component';
import { APP_BASE_HREF } from '@angular/common';
import { RadioNoComponent } from './radio-no/radio-no.component';
import { RadioYesComponent } from './radio-yes/radio-yes.component';
import { AuthErrorRadioYesComponent } from './auth-error-radio-yes/auth-error-radio-yes.component';
import { CoreService } from '../common/core.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthEligibleComponent } from './auth-eligible/auth-eligible.component';
import { DetailsComponent } from './details/details.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { NgxSpinnerModule } from "ngx-spinner";

const routes: Routes = [
  { path: 'CoastGuard', component: RadioYesComponent },
  { path: 'Others', component: RadioNoComponent },
  { path: '', component: RadioNoComponent},
  { path: '**', component: PageNotFoundComponent}
];
@NgModule({
  declarations: [
    AppComponent,
    AuthPrivacyComponent,
    AuthWhyComponent,
    AuthLeavingComponent,
    AuthErrorComponent,
    AuthFullSSNComponent,
    AuthErrorPopupComponent,
    RadioNoComponent,
    RadioYesComponent,
    AuthErrorRadioYesComponent,
    PageNotFoundComponent,
    AuthEligibleComponent,
    DetailsComponent,
    SystemErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    NgbModule
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: APP_BASE_HREF, useValue: '/'}, CoreService, NgbActiveModal],
  bootstrap: [AppComponent],
  entryComponents: [
    AuthWhyComponent,
    AuthErrorComponent,
    AuthErrorPopupComponent,
    AuthFullSSNComponent,
    AuthPrivacyComponent,
    AuthLeavingComponent,
    AuthErrorRadioYesComponent,
    AuthEligibleComponent,
    DetailsComponent,
    SystemErrorComponent,
  ]
})
export class AppModule { }
