import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { CoreService } from '../common/core.service';
import { environment } from 'src/environments/environment';
import { AuthPrivacyComponent } from './auth-privacy/auth-privacy.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AmericanForcesTravel';
  category;
  callbackuri;
  action:any;
  callback_uri:any;
  currentPath: any;
  data: any;
  rememberme:any;
  token:any;
  eventUrlArray :any;
  url:any;
  gaId;

  constructor(private modalService: NgbModal, private routing: ActivatedRoute, private router: Router,
    private coreService: CoreService) {
      this.gaId= environment.GAId;
    //  Getting pathname when redirected from priceline website
    window.location.pathname === '/' ? this.category = "/Others" : this.category = window.location.pathname;
    this.currentPath = this.category;
    if (window.location.pathname !== '') {
      this.callbackuri = window.location.search.split('?')[1];
    }
    // Service for getting submitted(CoastGuard and Others) data
    this.coreService.googleAnalytics$.subscribe(value => {
      this.data = value;
      if(this.data != undefined){
        this.action = this.data.Action;
        if (this.action != undefined) {
          this.callback_uri = this.data.Callback_uri.split('&')[1];
          this.rememberme = this.data.remember_me;
          this.token = this.data.Token;
          this.googleAnalytics();
        }
      }
      
    })
    //  Routing switching capturing
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        if (this.url === "/" || this.url === "/CoastGuard" || this.url === "/Others") {
          event.url === '/' ? this.category = "/Others" : this.category = event.url;
          this.currentPath = this.category;
        }
        else {
          this.eventUrlArray = event.url.split('?');
          this.eventUrlArray[0] === '/' ? this.category = "/Others" : this.category = this.eventUrlArray[0];
          this.callbackuri = this.eventUrlArray[1];
          this.currentPath = this.category;
        }
        this.action = undefined;
        this.googleAnalytics();
      }
    });
  }

   /**
   * Open popup for privacy
   */
  openPrivacy() {
    const modalRef = this.modalService.open(AuthPrivacyComponent, { size: 'lg' });
  }

  /**
   * Google Analytics 
   */
  googleAnalytics() {
     // Create default tracking object 
     (<any>window).ga('create', this.gaId, 'auto');   
    // When the user lands on the auth form(CoastGuard or Others)
    if ((this.category === '/CoastGuard') && (this.action === undefined)) {
      var newPagePath = this.currentPath.replace('/CoastGuard', '/CoastGuard/auth/?' + this.callbackuri);
    }
    else if ((this.category === '/Others') && (this.action === undefined)) {
      var newPagePath = this.currentPath.replace('/Others', '/Others/auth/?' + this.callbackuri);
    }
    // If the user has submitted the form successfully, before they are redirected to the AFT website
    else if ((this.category === '/CoastGuard') && (this.action === "Success")) {
      var newPagePath = this.currentPath.replace('/CoastGuard', '/CoastGuard/auth/success/?' + this.callback_uri);
    }
    else if ((this.category === '/Others') && (this.action === "Success")) {
      var newPagePath = this.currentPath.replace('/Others', '/Others/auth/success/?' + this.callback_uri);
    }
    // If the user has submitted the form but there is some sort of system error
    else if ((this.category === '/CoastGuard') && (this.action === "Error")) {
      var newPagePath = this.currentPath.replace('/CoastGuard', '/CoastGuard/auth/error/?' + this.callback_uri);
    }
    else if ((this.category === '/Others') && (this.action === "Error")) {
      var newPagePath = this.currentPath.replace('/Others', '/Others/auth/error/?' + this.callback_uri);
    }
    // If the user has submitted the form but their account could not be found
    else if ((this.category === '/CoastGuard') && (this.action === "Invalid")) {
      var newPagePath = this.currentPath.replace('/CoastGuard', '/CoastGuard/auth/invalid/?' + this.callback_uri);
    }
    else if ((this.category === '/Others') && (this.action === "Invalid")) {
      var newPagePath = this.currentPath.replace('/Others', '/Others/auth/invalid/?' + this.callback_uri);
    }
    // this updates the page name before sending it to Google Analytics
    //set pageview hit to web property 1 via default tracker
    (<any>window).ga('set', 'page', newPagePath);
    //send pageview hit to web property 1 via default tracker
    (<any>window).ga('send', 'pageview');
  }
}

