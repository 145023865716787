<div class="modal-header">
        <div class="strike"></div>
        <h4 class="modal-title"><span>PRIVACY ACT STATEMENT</span></h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span style="font-size:18px;">Close</span><span aria-hidden="true" style="font-size:25px;">&times;</span>
  </button>
</div>
<div class="modal-body">

           <section>

              <div class="xppn_card">

                  <div class="ppn_numbered">

                      <div class="ppn_numbered__item">
                          <span class="ppn_number">1</span><strong>AUTHORITY:</strong> DoD Instruction 1015.10, “Military Morale, Welfare, and Recreation (MWR) Programs;” Army Regulation 215-1, “Military Morale, Welfare, and Recreation Programs and Non-appropriated Fund Instrumentalities;” Commander Navy Installations Command (CNIC) Instruction 1710.3, “Operation of Morale, Welfare and Recreation Programs;” Air Force Instruction 34-101, “Air Force Morale, Welfare and Recreation (MWR) Programs and Use Eligibility;” Marine Corps Order 1700.29, “Marine Corps Recreation Programs;” and “Coast Guard Morale, Well-Being, and Recreation Manual,” Commandant Instruction M1710.13(series).
                      </div>

                      <div class="ppn_numbered__item">

                          <span class="ppn_number">2</span><strong>PRINCIPAL PURPOSE(S): </strong>Information is collected to verify access eligibility to AmericanForcesTravel.com website.  Suppliers have agreed to provide exclusive pricing for eligible users only.
                      </div>

                      <div class="ppn_numbered__item">
                          <span class="ppn_number">3</span><strong>ROUTINE USES: </strong>In addition to those disclosures generally permitted under 5 U.S.C. 552a(b) of the Privacy Act, these records will be used to determine branch of service for commission distribution purposes.  PII will not be shared or transferred to any third party.
                      </div>

                      <div class="ppn_numbered__item">

                          <span class="ppn_number">4</span><strong>DISCLOSURE: </strong> Voluntary. However, failure to provide all the requested information may result in denial of access to AmericanForcesTravel.com
                      </div>
                  </div>
              </div>
          </section>
          </div>
          <div class="modal-footer">
            Last Updated September 12 2018.
          </div>


