import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-error',
  templateUrl: './auth-error.component.html',
  styleUrls: ['./auth-error.component.css']
})
export class AuthErrorComponent implements OnInit {

  @Input() res: any;
  expandWhoWeServe = false;
  expandInformationCorrect = false;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  toggle() {
    this.expandWhoWeServe = !this.expandWhoWeServe;
    this.expandInformationCorrect = false;
  }

  toggleInformationCorrect() {
    this.expandInformationCorrect = !this.expandInformationCorrect;
    this.expandWhoWeServe = false;
  }

}
