<div class="modal-header">
  <div class="strike"></div>
  <h4 class="modal-title">
    <span>"KEEP ME LOGGED IN" CHECKBOX</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span style="font-size:18px;">Close</span>
    <span aria-hidden="true" style="font-size:25px;">&times;</span>
  </button>

</div>
<div class="modal-body">
  <section>

    <div class="xppn_card">
      <ul style="line-height: 32px;margin-left: -20px;">
        <li>Choosing "Keep me logged in" reduces the number of times you're asked to log in on this computer.</li>
        <li>To keep your account secure, use this option only on your personal computers.</li>
        <li>We do not store any of your personal information to keep you logged in.</li>
      </ul>
    </div>
  </section>
</div>