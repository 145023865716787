<div class="modal-header">
    <div class="strike"></div>
    <h4 class="modal-title"><span>Error</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span style="font-size:18px;">Close</span><span aria-hidden="true" style="font-size:25px;">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <section>
  
    <div class="xppn_card">
        <h4>{{res.status}} : {{res.description}}</h4>
      Please try again later!
      </div>
    </section>
  </div>
