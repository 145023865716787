import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-auth-why',
  templateUrl: './auth-why.component.html',
  styleUrls: ['./auth-why.component.css']
})
export class AuthWhyComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }

}
