import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthErrorComponent } from '../auth-error/auth-error.component';
import { SystemErrorComponent } from '../system-error/system-error.component';
import { CoreService } from '../../common/core.service';
import { AuthEligibleComponent } from '../auth-eligible/auth-eligible.component';
import { environment } from 'src/environments/environment';
import { DetailsComponent } from '../details/details.component';
import { timeout } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-radio-no',
  templateUrl: './radio-no.component.html',
  styleUrls: ['./radio-no.component.css']
})
export class RadioNoComponent implements OnInit {
  title = 'AmericanForcesTravel';
  referrer = '';
  ssn = ''; dob: any; lastName : any; rememberMe : any;
  closeResult: any;
  fourDigits = '^[0-9]{4}$';
  flag = false;
  invalidDate = false;
  public months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dd = '';
  mm = '';
  yy = '';
  model: any = { rememberMe: '0', dob: '', lastName: '', ssn: '', bFullSSN: false };
  submitted = false;
  fullssn = false;
  ssnvisibility = false;
  ssnfieldtype = 'password';
  othersUrl;
  isCoastGuard = 'Others';
  googleAnalytics : any;
  searchparam: any;
  callbackuri: any;
  post_response: any;
  modalRef:any;
  lastName_temp:any;
  constructor(private modalService: NgbModal, private routing: ActivatedRoute,
    private http: HttpClient, private route: Router, private coreService: CoreService,private SpinnerService: NgxSpinnerService) {
    this.othersUrl = environment.Others;
  }
  /**
   * Handle changes according to radio button click
   * @param es 
   */
  handleChange(es) {
        this.isCoastGuard = 'CoastGuard';
        this.route.navigate(["CoastGuard"], { queryParams: { callback_uri: decodeURIComponent(this.searchparam.callback_uri) } });
  }

  ngOnInit() {
    console.log(this.route.url);
    this.searchparam = this.searchToObject();
    this.referrer = this.searchparam['callback_uri'];
  }

  /**
   * Getting callback_uri
   */

  
  searchToObject() {
    const pairs = window.location.search.substring(1).split('&'),
    obj:any ={}
    let pair;
    for (const i in pairs) {
      if (pairs[i] === '') { continue; }
      pair = pairs[i].split('=');
      obj[decodeURIComponent(pair[0])] = (pair[1]);
    }
    return obj;
  }

  /**
   * Get years till current year
   */
  public years() {
    const currentYear = new Date().getFullYear();
    const y = [];
    for (let i = currentYear; i >= 1900; i--) { y.push('' + i); }
    return y;
  }

  /**
   * Get month list
   */
  public monthList() {
    if (this.flag === true) {
      const currentMonth = new Date().getMonth();
      const m = [];
      this.months.filter(function (value, index, arr) {
        if (index <= currentMonth) {
          m.push(value)
        }
      });
      return m;
    }
    else {
      const m = this.months;
      return m;
    }
  }

  /**
   * Get day list
   */
  public days() {
    
    if (this.flag === true) {
      var currentDate = new Date().getDate();
      const d = [];
      for (let i = 1; i <= currentDate; i++) { d.push(i); }
      return d;
    }
    else {
      const d = [];
      for (let i = 1; i <= 31; i++) { d.push(i); }
      return d;
    }
  }

  /**
   * Changes in years
   * @param year 
   */
  
  changeYear(year: any) {
    const currentYear = new Date().getFullYear();
    if (year === currentYear.toString()) {
      this.flag = true;
      this.days();
      this.monthList();
    }
    else {
      this.flag = false;
    }
  }

  /**
   * Validation for invalid date of birth
   * @param mm 
   * @param dd 
   * @param yy 
   */

  invalidDob(mm:any, dd:any, yy:any) {
    this.invalidDate = false;
    if (mm == "" || dd == "" || yy == "") {
      this.invalidDate = true;
      return true;
    }
  }

  open(content:any) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  /**
   * Open popup for any server side error
   * @param resp 
   */

  /**
   * Get full ssn
   */
  opengetfullSSN() {
    this.fullssn = true;
    this.submitted = false;
  }

  /**
   * toggle ssn visibility
   * @param item 
   */
  toggleSsnVisibility() {
    this.ssnvisibility = !this.ssnvisibility;
    this.ssnfieldtype = this.ssnvisibility ? 'text' : 'password';
  }


  /**
   * Open popup for why this information required 
   */
  openEligible() {
    const modalRef = this.modalService.open(AuthEligibleComponent, { size: 'lg' });
  }
  /**
   * Open details
   */
  openDetails() {
    const modalRef = this.modalService.open(DetailsComponent, { size: 'lg' });
  }
  /**
   * Submit no button details
   * @param fullSSN 
   */
  onSubmit(fullSSN: boolean) {
    this.lastName_temp = this.model.lastName;
    let d = this.dd || '00';
    if (d.length === 1) { d = '0' + d; }
    let m = (this.months.indexOf(this.mm) + 1).toString() || '00';//.padStart(2, '0') || '00';
    if (m.length === 1) { m = '0' + m; }
    let y = this.yy || '0000';
    this.model.dob = `${d}/${m}/${y}`;
    this.model.lastName = this.model.lastName.toUpperCase();
    this.submitted = true;
    this.SpinnerService.show();
    if(fullSSN == true){
      this.model.bFullSSN = true;
    }
    this.model.rememberMe = (this.rememberMe === true) ? '1' : '0';
    const request = this.http.post(this.othersUrl, this.model, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(timeout(5000));
    request.subscribe(data => {
      this.post_response = data;
      this.SpinnerService.hide();
      const refer = this.referrer;
      const callback = refer ? `&callback_uri=${refer}` : '';
      if (this.post_response.status == 'L902' || this.post_response.status == 'L903' || this.post_response.status == 'L904' || this.post_response.status == 'L905' || this.post_response.status == 'L906' || this.post_response.status == 'S909') {
        this.submitted = false;
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token };
        this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      else if (this.post_response.status == 'L907') {
        this.submitted = false;
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token };
        this.modalRef = this.modalService.open(AuthErrorComponent, { size: 'lg',windowClass: 'dark-modal' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      else if (this.post_response.status === 'L100') {
        this.submitted = true;
        this.googleAnalytics = { "Action": "Success", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token }
        const uriencoded = `https://www.americanforcestravel.com/wl/sso/?remember_me=${this.post_response.rememberMe}&token=${this.post_response.token}${callback}`;
        window.location.href = uriencoded;
      }
      else if ((this.post_response.status == 'S908' || this.post_response.status == 'S101' || this.post_response.status == 'S102' || this.post_response.status == 'S103' || this.post_response.status == 'S104' || this.post_response.status == 'S105' || this.post_response.status == 'S901') && (!fullSSN)) {
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token };
        this.opengetfullSSN();
        this.submitted = false;
      }
      else if (this.post_response.status == 'S908' || this.post_response.status == 'S101' || this.post_response.status == 'S102' || this.post_response.status == 'S103' || this.post_response.status == 'S104' || this.post_response.status == 'S105' || this.post_response.status == 'S901') {
        this.submitted = false;
        this.googleAnalytics = { "Action": "Invalid", "Callback_uri": callback, "remember_me": this.post_response.rememberMe, "Token": this.post_response.token };
        this.modalRef = this.modalService.open(AuthErrorComponent, { size: 'lg' });
        this.modalRef.componentInstance.res = this.post_response;
      }
      this.coreService.getGoogleAnalytics(this.googleAnalytics);
    },
    err => {
      this.SpinnerService.hide();
      this.submitted = false;
        this.modalRef = this.modalService.open(SystemErrorComponent, { size: 'lg' });
    }
    );
  }

  private getDismissReason(reason: any): string {
    return '';
  }

}
