
  <header class="p_header-opps">
    <div class="modal-header">
      <!-- <div class="strike"></div> -->
      <div class="modal-title-oops">
        <img class="whensmall dodlogo mobile_logo" src="./assets/images/logo-dod-small.png"
          alt="Department of Defense logo">
        <span class="modal-header-span">System Maintenance in Progress</span>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span style="font-size:18px;">Close</span>
        <span aria-hidden="true" style="font-size:25px;">&times;</span>
      </button>
    </div>
  </header>
  <div class="modal-body" style="background-color: white;">
    <section class="container">
      <div>  
        
        <!-- need to change when get the response -->
      <p *ngIf="res" style="display:inline-block; font-weight: bold;">{{res.status}} - {{res.description}} </p>
   <br>
      System maintenance in progress. Please try again later.
    </div>
  
    </section>
  </div>


